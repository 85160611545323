import React, { useState, useEffect } from 'react'
import './App.css'
import { withRouter } from 'react-router-dom'
import { Navbar, Nav } from 'react-bootstrap'
import { LinkContainer } from 'react-router-bootstrap'
import Routes from './Routes'

function App (props) {
  const [isAuthenticated, userHasAuthenticated] = useState(false)
  const [isAuthenticating, setAuthenticating] = useState(true)
  useEffect(() => {
    async function onLoad () {
      var user = window.localStorage.getItem('user')
      if (user) {
        // window.localStorage.removeItem('user')
        userHasAuthenticated(true)
        const pname = props.location.pathname
        if (pname === '/') {
          props.history.push('/home')
        } else {
          props.history.push(props.location.pathname)
        }
      }
      setAuthenticating(false)
    }
    onLoad()
  }, [props.location.pathname, props.history])

  async function logout (e) {
    e.preventDefault()
    window.localStorage.removeItem('user')
    userHasAuthenticated(false)
    props.history.push('/')
  }

  return (
    !isAuthenticating && <div className='App container'>
      <Navbar className='p-3 pb-3'>
        <LinkContainer to='/home'>
          <Navbar.Brand>
            <img
              src='/favicon-32x32.png'
              className='d-inline-block align-top'
              alt='Hello World logo'
            />
            Hello World Text Tool
          </Navbar.Brand>
        </LinkContainer>
        {isAuthenticated && <Nav className='ml-auto'>
          <Nav.Link href='/history'>Campaigns</Nav.Link>
          <Nav.Link href='/stats'>Stats</Nav.Link>
          <Nav.Link onClick={logout}>Logout</Nav.Link>
        </Nav>}
      </Navbar>
      <Routes appProps={{ isAuthenticated, userHasAuthenticated }} />
    </div>
  )
}

export default withRouter(App)
