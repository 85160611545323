import React, { useState } from 'react'
import { Form, Button, Spinner } from 'react-bootstrap'
import { useFormFields } from '../components/hooksLibs'
import config from '../config.js'
const fetch = require('cross-fetch')

export default function Login (props) {
  const [fields, handleFieldChange] = useFormFields({
    username: '',
    password: ''
  })
  const [isLoading, setIsLoading] = useState(false)
  const [badLogin, setBadLogin] = useState(false)
  const [msgLogin, setMsgLogin] = useState('')

  function logout () {
    // remove user from local storage to log user out
    window.localStorage.removeItem('user')
  }

  async function login (username, password) {
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ username, password })
    }

    const response = await fetch(`${config.apiUrl}/users/authenticate`, requestOptions)
    const user = await handleResponse(response)
    // login successful if there's a user in the response
    if (user) {
      // store user details and basic auth credentials in local storage
      // to keep user logged in between page refreshes
      user.authdata = window.btoa(username + ':' + password)
      window.localStorage.setItem('user', JSON.stringify(user))
    }
    return user
  }

  function handleResponse (response) {
    return response.text().then(text => {
      const data = text && JSON.parse(text)
      if (!response.ok) {
        if (response.status === 401) {
          // auto logout if 401 response returned from api
          logout()
          window.location.reload(true)
        }

        const error = (data && data.message) || response.statusText
        return Promise.reject(error)
      }

      return data
    })
  }
  async function handleSubmit (event) {
    event.preventDefault()
    setIsLoading(true)
    try {
      await login(fields.username, fields.password)
      props.userHasAuthenticated(true)
      props.history.push('/home')
      setIsLoading(false)
    } catch (e) {
      setBadLogin(true)
      setMsgLogin(<div className='d-flex text-center text-danger p-3'>Your credentials are incorrect.</div>)
      setIsLoading(false)
    }
  }

  return (
    <div className='d-flex flex-column align-items-center justify-content-center h-75'>
      <Form onSubmit={handleSubmit} style={{ minWidth: '250px' }}>
        <Form.Group controlId='username' size='lg'>
          <Form.Label>Username</Form.Label>
          <Form.Control
            autoFocus
            type='text'
            value={fields.username}
            onChange={handleFieldChange}
          />
        </Form.Group>
        <Form.Group controlId='password' size='lg'>
          <Form.Label>Password</Form.Label>
          <Form.Control
            value={fields.password}
            onChange={handleFieldChange}
            type='password'
          />
        </Form.Group>
        <div className='d-flex flex-column text-center'>
          {isLoading ? (
            <Button block disabled type='submit' variant='primary'>
              <Spinner
                as='span'
                animation='border'
                role='status'
                aria-hidden='true'
                size='lg'
              />
            </Button>
          )
            : (
              <Button block type='submit' disabled={((!fields.username && !fields.password) | (badLogin))} variant='primary'>
                            Login
              </Button>
            )}
        </div>
      </Form>
      {badLogin && msgLogin}
    </div>
  )
}
