
export function authHeader () {
  // return authorization header with basic auth credentials
  const user = JSON.parse(window.localStorage.getItem('user'))

  if (user && user.authdata) {
    return { Authorization: 'Basic ' + user.authdata }
  } else {
    return {}
  }
}
