import React, { useEffect, useState } from 'react'
import { authHeader } from '../components/authHeader'
import config from '../config'
import { Spinner, Jumbotron, Button } from 'react-bootstrap'
import PivotTableUI from 'react-pivottable/PivotTableUI'
import './pivottable.css'
const fetch = require('cross-fetch')

export default function CampaignPreview (props) {
  const [isLoading, setIsLoading] = useState(false)
  const [campaignData, setCampaignData] = useState({})
  const [phones, setPhones] = useState([])
  const [stats, setStats] = useState([])
  const [show, setShow] = useState(false)
  const [table, setTable] = useState({})
  function timeConverter (timestamp) {
    var a = new Date(timestamp)
    var months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
    var year = a.getFullYear()
    var month = months[a.getMonth()]
    var date = a.getDate()
    var hour = a.getHours()
    var min = a.getMinutes()
    var time = date + ' ' + month + ' ' + year + ' ' + hour + ':' + min
    return time
  }
  useEffect(() => {
    async function onLoad () {
      setIsLoading(true)
      const campaignId = props.match.params.id
      const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', ...authHeader() }
      }
      const response = await fetch(`${config.apiUrl}/campaigns/${campaignId}`, requestOptions)
      const cD = JSON.parse(await response.text())
      setCampaignData(cD)
      setPhones(cD.phones)
      setIsLoading(false)
    }
    onLoad()
  }, [props.match.params.id])

  async function renderStats () {
    const campaignId = props.match.params.id
    const requestOptions = {
      method: 'GET',
      headers: { 'Content-Type': 'application/json', ...authHeader() }
    }
    const response = await fetch(`${config.apiUrl}/stats/${campaignId}`, requestOptions)
    var loadedStats = JSON.parse(await response.text())
    setStats(loadedStats)
    setShow(true)
    console.log(loadedStats)
  }
  return (!isLoading && <div>
    <h1 className='pb-3'>Campaign Status</h1>
    <div className='text-left'>
      <p> Campaign Name: <span className='font-weight-bold'>{campaignData.name}</span></p>
      <p>Created on: <span className='font-weight-bold'>{timeConverter(campaignData.createdAt)}</span></p>
      <p>Number of contacts: <span className='font-weight-bold'>{phones.length}</span></p>
      <p>Text content:</p>
    </div>
    <Jumbotron>
      {campaignData.content}
    </Jumbotron>
    <div className='mx-auto'>
      {(campaignData.status === 'processing') && <>
        <p>The campaign is still processing. Refresh in a few seconds.</p>
        <Spinner animation='border' />
      </>}
      {(campaignData.status === 'processed') && <>
        <p>The campaign was a success.</p>
        <Button disabled={show} onClick={renderStats}>Show Pivot Table</Button>
        <div className='p-3' />
          {(show && stats) && (
            <>
            <p>Labels of the pivot table can be drag'n dropped.</p>
            <p>Cost is in US Dollars.</p>
            <p>To get delivery numbers:<br />
            Click on 'Sum' and select 'Count'.<br />
            Drag 'Status' in columns.</p>
            <PivotTableUI
              data={stats}
              rows={['country']}
              vals={['cost']}
              aggregatorName={'Sum'}
              onChange={s => setTable(s)}
              {...table}
            />
            </>)}
        <div className='p-3' />
      </>}
    </div>
  </div>
  )
}
