import React from 'react'
import { Route, Switch } from 'react-router-dom'
import AuthenticatedRoute from './components/AuthenticatedRoute'
import UnauthenticatedRoute from './components/UnauthenticatedRoute'
import Home from './containers/Home'
import Login from './containers/Login'
import NotFound from './containers/NotFound'
import CampaignPreview from './containers/CampaignPreview'
import CampaignHistory from './containers/CampaignHistory'
import Stats from './containers/Stats'

export default function Routes ({ appProps }) {
  return (
    <Switch>
      <UnauthenticatedRoute path='/' exact component={Login} appProps={appProps} />
      <AuthenticatedRoute path='/home' exact component={Home} appProps={appProps} />
      <AuthenticatedRoute path='/campaign/status/:id' exact component={CampaignPreview} appProps={appProps} />
      <AuthenticatedRoute path='/history' exact component={CampaignHistory} appProps={appProps} />
      <AuthenticatedRoute path='/stats' exact component={Stats} appProps={appProps} />
      <Route component={NotFound} />
    </Switch>
  )
}
