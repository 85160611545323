import { useState } from 'react'

export function useFormFields (initialState) {
  const [fields, setValues] = useState(initialState)
  var txt = ''
  return [
    fields,
    function (event) {
      if (event.manual) {
        setValues({
          smsText: event.smsText,
          name: event.name,
          sender: event.sender
        })
        return true
      } else {
        event.preventDefault()
        if (!event.clipboardData) {
          setValues({
            ...fields,
            [event.target.id]: event.target.value
          })
        } else {
          if (event.clipboardData.getData('text/html')) {
            txt = event.clipboardData.getData('text/html')
          } else {
            txt = event.clipboardData.getData('Text')
          }
          setValues({
            ...fields,
            [event.target.id]: txt
          })
        }
      }
    }
  ]
}
