import React, { useEffect, useState } from 'react'
import { authHeader } from '../components/authHeader'
import config from '../config'
import { Spinner } from 'react-bootstrap'
import PivotTableUI from 'react-pivottable/PivotTableUI'
import './pivottable.css'
const fetch = require('cross-fetch')

export default function CampaignPreview (props) {
  const [isLoading, setIsLoading] = useState(false)
  const [stats, setStats] = useState([])
  const [table, setTable] = useState({})
  function timeConverter (timestamp) {
    var a = new Date(timestamp)
    var months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
    return { year: a.getFullYear(),
      month: months[a.getMonth()],
      day: a.getDate(),
      hour: a.getHours(),
      min: a.getMinutes() }
  }
  useEffect(() => {
    async function onLoad () {
      setIsLoading(true)
      const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', ...authHeader() }
      }
      const response = await fetch(`${config.apiUrl}/stats`, requestOptions)
      const data = JSON.parse(await response.text())
      const processDataTime = data.map(item => {
        var date = timeConverter(Date.parse(item.timestamp))
        delete item.timestamp
        return { ...item, ...date }
      })
      setStats(processDataTime)
      console.log(processDataTime)
      setIsLoading(false)
    }
    onLoad()
  }, [])
  return <div>
    <h1 className='pb-3'>Delivery Statistics</h1>
    <p>Labels are drag'n droppable.</p>
    <p>To have a cost breakdown per country:<br />
    Click on 'Count', and select 'Sum' then 'cost'.<br />
    Drag the 'status' label back on top.</p>
    <p>Cost is in US Dollars.</p>
    {(!(!isLoading && stats)) ? <Spinner className='mx-auto' animation='border' /> : (
      <PivotTableUI
        data={stats}
        rows={['country']}
        cols={['status']}
        onChange={s => setTable(s)}
        {...table}
      />)}
  </div>
}
