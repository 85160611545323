import React, { useState, useRef } from 'react'
import { Row, Col, Button, Table, Spinner, Form } from 'react-bootstrap'
import { useFormFields } from '../components/hooksLibs'
import readXlsxFile from 'read-excel-file'
import config from '../config'
import { authHeader } from '../components/authHeader'
const fetch = require('cross-fetch')
const phone = require('phone')

export default function Home (props) {
  const [fields, handleFieldChange] = useFormFields({
    name: '',
    smsText: ''
  })
  const [isLoading, setIsLoading] = useState(false)
  const [contactError, setContactError] = useState('')
  const [contacts, setContacts] = useState([])
  const [contactsLoading, isContactsLoading] = useState(true)
  const [contactFileUploaded, setContactFileUploaded] = useState(false)
  const [validatedPhones, setValidatedPhones] = useState([])
  const [phonesMessage, setPhoneMessage] = useState('')
  const contactsFile = useRef(null)

  async function handleSubmit (e) {
    e.preventDefault()
    setIsLoading(true)
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json', ...authHeader() },
      body: JSON.stringify({
        name: fields.name,
        content: fields.smsText,
        phones: validatedPhones,
        createdAt: Date.now(),
        status: 'creation'
      })
    }

    const response = await fetch(`${config.apiUrl}/campaigns`, requestOptions)
    const campaignId = JSON.parse(await response.text())
    setIsLoading(false)
    await props.history.push(`/campaign/status/${campaignId.campaignId}`)
  }
  function getFileExtension (filename) {
    var filenamecase = filename.toLowerCase()
    const array = filenamecase.split('.')
    return array[array.length - 1]
  }

  async function handleContacts (event) {
    try {
      setContactError('')
      isContactsLoading(true)
      setContactFileUploaded(true)
      contactsFile.current = event.target.files[0]
      const extension = getFileExtension(contactsFile.current.name)
      if (!['xls', 'xlsx'].includes(extension)) {
        setContactError('Your file extension is not xls or xlsx')
        isContactsLoading(false)
        return false
      }
      await readXlsxFile(contactsFile.current).then(async (rows) => {
        if (!rows[0].includes('phone')) {
          setContactError('The "phone" column is required !')
        }
        var contactRows = rows.map((arr) => {
          return arr.map((item) => (item === null) ? '' : item)
        })
        // validate phones
        setContacts(contactRows)
        var phoneIx = contactRows[0].findIndex(x => x === 'phone')
        var phones = contactRows.slice(1).map(row => row[phoneIx])
        var validatedPhones = phones.map(p => {
          const validatedPhone = phone(p)
          if (validatedPhone.length > 0) {
            return { phone: validatedPhone[0], country: validatedPhone[1] }
          } else {
            return null
          }
        })
        const filteredPhones = validatedPhones.filter(x => x !== null)
        setValidatedPhones(filteredPhones)
        setPhoneMessage('This file contains ' + filteredPhones.length.toString() + ' valid phones out of ' + phones.length.toString() + '.')
      }).catch(
        e => {
          console.log(e)
          setContactError('There was an error reading your file. Is it an excel file ?')
        }
      )
    } catch (e) {
      setContactError('Are you uploading an Excel file ?')
    }
    isContactsLoading(false)
  }

  function previewContacts () {
    return (
      contactsLoading ? <Spinner className='p-3' animation='border' />
        : ((contactError === '')
          ? (
            <div className='pt-2'>
              <Form.Text>Below is a preview of your contacts.</Form.Text>
              <Form.Text>{phonesMessage}</Form.Text>
              <Form.Text>Non valid phones are automatically removed.</Form.Text>
              <Row className='pt-2'>
                <Col>
                  <Table style={{ maxWidth: '350px' }} responsive className='p-3'>
                    <thead>
                      <tr>
                        {contacts[0].map(
                          (header, i) => {
                            return <th key={i}>{header}</th>
                          }
                        )}
                      </tr>
                    </thead>
                    <tbody>
                      {contacts.slice(1, 5).map(
                        (contact, i) => {
                          return (
                            <tr key={i}>
                              {contact.map((c, i) => { return <td key={i}>{c ? c.toString() : ''}</td> })}
                            </tr>
                          )
                        }
                      )}
                    </tbody>
                  </Table>
                </Col>
              </Row>
            </div>
          )
          : (
            <div className='text-danger'>
              {contactError}
            </div>
          )
        )
    )
  }

  function renderForm () {
    return (
      <Form
        onSubmit={handleSubmit}
        className='d-flex align-items-center flex-column'
      >
        <Form.Group className='p-3' controlId='name'>
          <Form.Label>Campaign Name</Form.Label>
          <Form.Control
            required
            autoFocus
            type='input'
            placeholder='US Numbers June 2020'
            onChange={handleFieldChange}
            style={{ minWidth: '500px' }}
            value={fields.name}
            maxLength={50}
          />
          <Form.Control.Feedback type='invalid'>A campaign name is required</Form.Control.Feedback>
        </Form.Group>
        <Form.Group controlId='contacts'>
          <Form.Label>Your Contacts File</Form.Label>
          <Form.Control
            className='pl-5'
            required
            ref={contactsFile}
            as='input'
            type='file'
            onChange={handleContacts}
          />
          {contactFileUploaded ? previewContacts(contacts[0])
            : <Form.Text>
              Only supports xls or xlsx files. <br />
              Here's an example of a <a href='https://sms-excel-files.s3.amazonaws.com/example.xlsx'>valid file</a>
              <br />Phones should be in E.164 format, for a US number: +1001XX100
            </Form.Text>}
          <Form.Control.Feedback type='invalid'>A contacts file is required</Form.Control.Feedback>
        </Form.Group>
        <Form.Group className='p-3' controlId='smsText'>
          <Form.Label>Your SMS content</Form.Label>
          <Form.Control
            required
            style={{ minHeight: '300px', minWidth: '500px' }}
            autoFocus
            as='textarea'
            type='input'
            placeholder="Hello, we're organizing a webinar next week. I think it can be interesting for you. Let me know if you want to be a part of it."
            onChange={handleFieldChange}
            value={fields.smsText}
          />
          {(validatedPhones.length > 0) && <>
          <Form.Text className='text-info'>{Math.max(Math.floor(fields.smsText.length / 160),1)} SMS is going to be sent per contact.</Form.Text>
          <Form.Text className='text-info'>For a campaign targeting the US only, this campaign would cost {Math.max(Math.floor(fields.smsText.length / 160),1) * 0.07 * validatedPhones.length}$</Form.Text>
          </>}
          <Form.Control.Feedback type='invalid'>SMS content is required</Form.Control.Feedback>
        </Form.Group>
        {isLoading ? (
          <Button disabled={isLoading} className='align-self-end'>
            <Spinner
              animation='border'
              role='status'
              aria-hidden='true'
              size='lg'
            />
          </Button>
        ) : (
          <Button disabled={isLoading} className='align-self-end' type='submit'>
                Create and launch the campaign
          </Button>
        )}
        <div className='pt-5' />
      </Form>
    )
  }
  return (<div>
    <h1 className='pb-3'>Sending Text Tool</h1>
    <p className='text-left text-info'>AWS bills 160 characters per SMS.<br />
    You can send longer texts, but you will be billed accordingly.<br />
    SMS pricing worldwide <a href='https://aws.amazon.com/sns/sms-pricing/'>can be found here</a>.<br />
    Most destination countries (US and France included) are around 0.07$ per text.<br />
    A quick estimation will be provided when you upload your contacts.</p>
    {renderForm()}
    <p className='text-left pb-3'></p>
  </div>)
}
