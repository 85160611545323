import React, { useEffect, useState } from 'react'
import { authHeader } from '../components/authHeader'
import config from '../config'
import { Table } from 'react-bootstrap'
import { LinkContainer } from 'react-router-bootstrap'
const fetch = require('cross-fetch')

export default function CampaignHistory (props) {
  const [isLoading, setIsLoading] = useState(false)
  const [campaignData, setCampaignData] = useState([])
  function timeConverter (timestamp) {
    var a = new Date(timestamp)
    var months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
    var year = a.getFullYear()
    var month = months[a.getMonth()]
    var date = a.getDate()
    var hour = a.getHours()
    var min = a.getMinutes()
    var time = date + ' ' + month + ' ' + year + ' ' + hour + ':' + min
    return time
  }
  useEffect(() => {
    async function onLoad () {
      setIsLoading(true)
      const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', ...authHeader() }
      }
      const response = await fetch(`${config.apiUrl}/campaigns`, requestOptions)
      const cD = JSON.parse(await response.text())
      setCampaignData(cD)
      setIsLoading(false)
    }
    onLoad()
  }, [])

  function formatCampaignData () {
    return campaignData.map((campaign) => {
      return (
        <LinkContainer key={campaign.campaignId} style={{ cursor: 'pointer' }} to={`campaign/status/${campaign.campaignId}`}>
          <tr>
            <td>{campaign.name}</td>
            <td>{campaign.phones.length.toString()}</td>
            <td>{campaign.content.slice(0, 50) + ' ...'}</td>
            <td>{campaign.status}</td>
            <td>{timeConverter(campaign.createdAt)}</td>
          </tr>
        </LinkContainer>)
    })
  }

  return (
    (!isLoading && campaignData) && <div>
      <h1 className='pb-3'>Campaign History</h1>
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>Campaign Name</th>
            <th>Number of contacts</th>
            <th>Content Snippet</th>
            <th>Status</th>
            <th>Created at</th>
          </tr>
        </thead>
        <tbody>
          {formatCampaignData()}
        </tbody>
      </Table>
    </div>
  )
}
